
p{
  margin: 1px 4px;
}
.idg{
  display: flex;
  flex-direction: row;
}
.BelowNav{
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  padding: 0%;
  overflow: auto;
   
}
.nav{
  background-color: rgba(253, 253, 253,0.6);
  border: #282c34;
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: 100%;
  height:50px;
  overflow: hidden;
  border-bottom: 2px solid black;
}
.SiteTitle{
  color: rgb(0, 0, 0);
  font-style: normal;
  size: 100px;
  text-decoration: none;
  padding: 0%;
  cursor: pointer;
margin: auto;
}


.NavBut2{
  color: rgb(0, 0, 0);
  padding: 5px 9px;
  max-width: 100px;
  float: left;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  cursor: pointer;
  border:1px solid #282c34;
  border-radius: 2px;
  margin-left: 3px;
}
.NavBut2:hover {
  color:#d4d4d4;
  background-color: #495457;;
  transition: 0.7s;
}

.NavBut3{
  color: rgb(0, 0, 0);
  float: right;
  padding: 1rem;
  max-width: 100px;
  min-width: 30px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
  cursor: pointer;
 

}
.NavBut3:hover {
  color:#495457;
  transition: 0.7s;
}

.HomeMain {
  width: 100%;
  height: 100%;
}

.CVPic, .CLPic {
  width: 250px; /* Adjust to your desired width */
  height: 50px; /* Adjust to your desired height */
  border-radius: 50px;
  background-color: #676fff;
  display: flex;
  padding: 5px;
  margin: 15px;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: white;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.CVPic:hover, .CLPic:hover{
  transform: scale(1.05);
  cursor: pointer;
}
.Homeboxbut {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.Homebox{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
 
}
.Gtext{
 color: gray;
 font-weight: bold;
 font-size: large; 
 margin: 10px;
}
.boxcont {
  width: 55%;
  height: 50%;
  margin: auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the box shadow */
  display: flex; 
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 15px 10px;
  background-color: white; /* Optional: Add a background color to make the shadow more visible */
}
.rowtop{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
}

.Bightext{
  color: rgb(50, 50, 50);
  margin: auto;
  text-align: justify;
  margin-bottom: 40px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.smallhtext{
  margin: auto;
  margin-top: 20px;
  color: azure;
  width: 90%;
  text-align: justify;
  
}
.button1,.button2{
  padding: 10px 30px;
  size: 20px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.button1{
 margin-right: 20px;
background-color: rgba(255,255,255,0.01);
color: antiquewhite;
border: 1px solid #282c34;
}
.button2{
  margin-left: 20px;
  background-color: #282c34;
  color: antiquewhite;
}

.creator {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.left-section { 
  flex: 40%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.right-section {
  margin:auto;
  flex: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.a4-sized-view {
  width: 100%; /* Full width */
  height: 100%;/* Full viewport height */
}
.insideA4{
  
  /* Add border as desired */
  width: 100%;
  height: 100vh;
   overflow: hidden;
   box-sizing: border-box; /* Include border in element's total width and height */
   display: flex;
   align-items: center;
   align-content: center;
   text-align: center;
}
.heading{
  color: #000;
  background-color: #282c34;
}
.a4-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* You can apply additional styles to .a4-sized-view as needed */



.form-group, .form-group1{
  margin-bottom: 15px;
  gap: 7px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 8px;
  min-width: 245px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #393939;
}
/* Add this CSS to your stylesheet */

.red-border {
  border: 1px solid red !important;
}

.maroonish-color2 {
  color: rgb(230, 153, 0); /* Adjust the color code to the maroonish color you want */
  /* Any other styles you want to apply */
  padding: 5px;
}


#resume {
  width: 210mm;
  height: 297mm;
  align-items: top;
  display: flex;
  flex-direction: column;
  border-style: solid;
} 
.insidecont {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  border: none;
  align-items: flex-start;
  text-align: left;
}  



/* Apply styles to the Abme div (the content) */
.Abme {
  
  flex: 1; /* Expand to fill remaining height */
  display: flex;
  flex-direction: column;
  padding: 5px;
}        

/* Apply styles to the Profile picture */

/* */
#Upper{
  
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

#Education_cont, #Work-container,#Language_cont,.Skills-cont,.IPSK-cont,#Project-cont,#Empty-cont{
  height: auto;
}

.Edu,.Work,.Lan,.Skills,.Projects,.Extra,.Items{
  padding: 1px;
}
.Lan{
width: max-content;
}

.Skills{
  padding-top: 5px;
}
.Work-container{
  margin-top: -10px;
}
.LS{
  margin-bottom: 4px;
}
.Skills,.Skills2{
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
}
#Job{
  display: flex;
  flex-direction: column;
  padding: 2px;
}
#Proj{
  display: flex;
  flex-direction: column;
  padding: 2px;
}
#Education{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
#bold{
  font-weight: bold;
  padding-right: 4px;
}
.ski{
  padding: 3px;
  margin-right: 1px;
  margin-left: 2px;
  color: #6d6d6d;
}

.MT,#MT{
  width: 100%;
  border-bottom-style: solid;  
  margin: 0;
  padding: 0;
}
.name {
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-weight: bold; /* Add this line to make the text bold */
padding: 10px;
}

.info1 {
 display: flex;
 padding: 7px 11px;
 align-items: baseline;
}

/* Add margin-right to create spacing between items */
.info1 text {
margin-right: 10px;
}
.info2{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 10px;
}
.info2 text {
margin-right: 10px;
}
#DName{
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.forBold{
  color: rgb(0, 0, 0);
  font-weight: bold;
  margin-top: 5px;
}
.forBold{
  margin-top: 5px;
}
.languageinside{
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: space-between;
}

#textonly{
  margin-top: 4px;
}
.Address{
  padding: 10px;
  margin-left: 10px;
}
.spacer{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
  align-items: flex-start;
}

.spacer text {
  margin-top: 10px;
}
.prof{
  width: 100%;
  align-items: flex-start;
  display: flex;
}
#institute{
   font-style: italic;
   font-weight: bold;
   font-size: 14px;
   color: #333333;
   margin-right: 4px;
   padding-top: 2px;
}
#jobTitle{font-weight: bold;}
#company{font-weight: bold;}
#Duration{margin-left: 2px;}
#Description{margin-top: 5px;}

#resumep2 {
  width: 210mm;
  height: 297mm;
  align-items: top;
  display: flex;
  flex-direction: column;
  border-style: solid;
}
.DOBer{
  width: 98%;
  padding: 10px;
  border: 0.5px solid black;
  border-radius: 0px;
  margin-top: 4px;
  margin-bottom: 5px;
}
.AddBut,.AddBut2{
  padding: 4px 6px 4px 6px;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 0px;
  background-color: #282c34;
}

.TA{
  width: 105%;
  height: 90px;
  padding: 3px;
}

  .adres1,.adres{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 14px;
    margin-top: 5px;
  }
  .adress{
    margin-bottom: 14px;
    display: flex;
    align-items: baseline;
    flex-direction: row;
  }
 #AbtMe{
  display: flex;
    flex-direction: row;
    align-items: flex-start;
 }
  .color-circle:hover, .color-circle1:hover, .color-circle2:hover, .color-circle3:hover
  , .color-circle4:hover, .color-circle5:hover, .color-circle6:hover,
  .color-circle7:hover, .color-circle8:hover, .color-circle9:hover,.color-circle10:hover
  ,.color-circle11:hover,.color-circle12:hover
  {
    cursor: pointer;
  }

  .color-picker-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  
  }
  
  .color-picker-container input {
    margin-bottom: 10px;
  }
  
  .color-circle1 {
    cursor: pointer;
  }
  
  /* Optional: Add a semi-transparent overlay to blur the background */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .skill-box {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Adjust the spacing between skill boxes */
  }
  .link,#linker{
    color: rgb(0, 204, 255);
    text-decoration: underline;
    word-wrap: break-word;
    word-break: break-all;
    padding: 0px;
  }
  .high{
    padding-left: 10px;
    display: flex;
    flex-direction: row;
  }
  .Ebull{
    display: none;
  }
.BaddText{
  margin-right: 5px;

}
.somespace{
  padding: 0px 7px;
  padding-top: 4px;
}
  .MainEdiv{
    display: flex;
    height: auto;
  }
#Empty_Inner{
  display: flex;
  flex-direction: column;
  width: 100%;
  }

  .EItem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    word-wrap: break-word;
  }
  #Afth,#Afth1,#Afth2{
    margin-left: 4px;
  }
  #Title{
    padding: 1px;
  }

  #UCLUP{
    display: flex;
    width: 100%;
    height: 240px;
  }
  #UCLNa{
    font-size: 25px;
    padding-left: 20px;
    padding-top: 50px;
    color: rgb(255, 255, 255);
  }
  #UCLPo{
    font-size: 18px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 50px;
    color: #000;
  }

  .UCLmid {
    display: grid;
    width: 100%;
    height: auto;
    padding: 15px 0px;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns with equal width */
    grid-template-rows: auto; /* Row height will adjust to content */
    gap: 2px; /* Adjust gap size between grid items as needed */
    justify-items: left; /* Center items horizontally in their cells */
    align-items: flex-start; /* Center items vertically in their cells */
  }
  
  /* Ensuring the images and divs within .UCLmid are also centered */
  .UCLmid img,
  .UCLmid div {
    justify-self: flex-start;
    align-self: center;
  }
  .UCLparas{
    height:auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
  .UCLa{
    size: 40px;
    padding-left: 30px;
    font-size: 14px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .UCLda{
    size: 40px;
    padding-left: 30px;
    color: #495457;
    font-size: 14px;
    font-style: italic;
  }
  .paras{
    text-align: justify;
    height: auto;
    padding: 30px;
    align-items: flex-start;
  }
  .UCLIL{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 90px;
  }
  .UCLImg{
  height: 20px;
  width: 20px;
  }
  #cover_letter{
    width: 210mm;
    height: 297mm;
    align-items: top;
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
  }
  .paraStyle {
    /* Style for paragraphs */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 10px 0;
    font-size: 14px;
  }
  
  .lineStyle {
    /* Style for lines */
    text-decoration: underline;
    margin: 5px 0;
  }
  
  .bulletStyle {
    font-size: 14px;
    margin: 5px 0 5px 20px; /* Example of indenting bullets */
    display: flex;
    
  }
  .bulletStyle::before {
    content: '\2022'; /* Bullet point character */
    margin-right: 5px; /* Adjust spacing */
  }
  #eml,#adr,#lkn,#phn{
    padding: 0px 4px;
  }
  .inf{
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 15px;
    background-color: #cbe9ff;
  }
  #inflink,#infddl{
    margin-bottom: 10px;
  }
  .round_button{
    height: 30px;
    width: 30px;
    border-radius: 20px;
    background-color: #717171;
    margin: 2px;
    cursor: pointer;
    display: flex; /* Add this */
    justify-content: center;
    align-items: center;
  }
 .NavigationButs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 }
 .bullet-styled::before {
  content: '\2022'; /* Bullet point character */
  margin-right: 5px; /* Adjust spacing */
}
.Bholder{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}
#Nation,#DOB,#gender{
  padding: 0;
  text-align: center;
  
}
.Buttons1{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
}
.rightside{
  margin-right: 0px;
  margin-left: 10px;
}
.leftside{
  margin-left: 0px;
}
.SecBut{
  width: 110%;
  text-align: end;
}
.Select{
  padding: 8px;
  width: 110%;
}
.TApara{
  width: 106%;
  height: auto; /* Initial height */
  resize: vertical; /* Allow vertical resizing */
  overflow-y: hidden;
  font-family: 'Times New Roman', Times, serif;
  border: none;
  border-radius: 8px;
  background-color: #eeeeee;
  box-shadow: #333333;
  margin-bottom: 5px;
}
.IPT{
  width: 100%;
  height: max-content;
  margin-bottom: 5px;
  overflow: auto;
}
.Salutations{
  margin: 30px;
}
.INBT{
  font-size: 14px;
}
.CoBut{
  background-color: #393939;
  border-radius: 50%; /* Make it a perfect circle */
  width: 36px; /* Adjusted width */
  height: 36px; /* Adjusted height */
  margin: 4px;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
}
.CoBut:hover{
  background-color: #717171;
  cursor: pointer;
}
.ButtonContCO{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 113%;
  align-content: space-between;
}
.TemplatesCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  overflow: scroll;
  height: 100vh;
  background: linear-gradient(to right, #eaf1f6, #24f4ff); /* Gradient background */
}



.Amazon, .Youtube, .Europass, .Programming {
  width: 80%;
  height: 80%;
  margin: 20px;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.3s;
}

.Amazon:hover, .Youtube:hover, .Europass:hover, .Programming:hover {
  transform: scale(1.05);
}


.Amazon{
  background-color: rgb(255, 166, 0); /* Change background color on hover */
}

.Youtube {
  background-color: red;
}

.Europass {
  background-color: rgb(231, 231, 231);
}
.Programming {
  background-color: rgb(60, 60, 60);
}

.DHL {
  background-color: yellow;
}
.circularA{
width: 100%;
height: 20%;
background-color: #000;
display: flex;
flex-direction: row;
margin-bottom: 30px;
justify-content: flex-start;
align-items: center;
}
.boxesA{
width: 90%;
height: 20%;
background-color: white;
margin: auto;
margin-bottom: 20px;
border: 3px solid rgb(0, 0, 0);
border-radius: 4px;
display: flex;
flex-direction: column;
}
.imagewala{
  width: 25%;
  height: 65%;
  border-radius: 50%;
  background-color: #b7b7b7;
  margin-top: auto; 
  margin-bottom: auto;
  margin-left: 5%;
}

.circularY{
  width: 100%;
  height: 20%;
  background-color: #f8f8f8;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  }
  .boxesY{
  width: 90%;
  height: 20%;
  background-color: white;
  margin: auto;
  margin-bottom: 20px;
  border: 3px solid rgb(0, 0, 0);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  }

 .circularE{
   width: 100%;
   height: 20%;
   background-color: #f8f8f8;
   display: flex;
    flex-direction: row;
  margin-bottom: 30px;
  }
  .boxesE{
  width: 90%;
  height: 20%;
  background-color: white;
  margin: auto;
  margin-bottom: 20px;
  border: 3px solid rgb(0, 0, 0);
  border-left: none;
  border-right: none;
  display: flex;
  flex-direction: column;
  }
  .boxesP{
    width: 90%;
    height: 20%;
    background-color: white;
    margin: auto;
    margin-bottom: 20px;
    border: 3px solid rgb(180, 0, 177);
    display: flex;
    flex-direction: column;
    }
    .circularP{
      width: 100%;
      height: 20%;
      background-color: #9e9e9e;
      display: flex;
       flex-direction: row;
     margin-bottom: 30px;
     }
  .deets{
    height: 80%;
    width: 60%;
    margin: auto;
    border: none;
    border-radius: 5px;
    background-color: #c2c2c2;
    display: flex;
    flex-direction: column;
  }
.lines{
  width: 80%;
  border: none;
  border-radius: 5px;
  background-color: #dedede;
  height: 10px;
  margin: auto;
}
.bullet-box {
  display: inline-block;
  margin-right: 10px; /* Adjust spacing between bullet-boxes */
}

.bullet {
  font-weight: bold;
}

.ipski {
  display: flex;
  flex-direction: row;
}

/* Styles for Box style */
.styled {
  border-radius: 5px;
  font-style: normal;
  padding: 3px;
  border: 0.5px solid #000; /* Set border thickness and color */
}

.box_style {
  border-radius: 5px;
  font-style: normal;
  padding: 3px;
  margin-left: 2px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  border: 0.5px solid #000; /* Set border thickness and color */
}
.main_container{
  display: flex;
  flex-direction: row;
  margin-left: 5px;
}

.outsider{
  display: flex;
  flex-direction: row;
  padding: 3px;
  flex-wrap: wrap;
 
}
/* Styles for Bullets style */
.bullet_style .bullet_if_required {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin-right: 3px;
  /* Adjust spacing between bullet and text */
}

.bullet_style .bullet_if_required::before {
  content: '\2022'; /* Bullet character */
}
.Top1{
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 70%;
  margin: 10px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
}
.Top2_text{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 70%;
  max-width: 800px;
  margin: 10px;
}
.Top1text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}
.FImage1{
  width: 280px;
  height: 280px;
  margin: 10px;
  margin-top: 20px;
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}
.FImage2{
  width: 680px;
  height: 230px;
  margin: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}
.parat{
  text-align: justify;
  margin: 5px;
}
.footer {
  background: #eeeeee;
  font-family: 'Play', sans-serif;
  display:flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
}

.row {
  width: 100%;
  margin: 1% 0;
  
  color: gray;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
  display: flex;
  
}

.smi:hover{
  color: white;
}
.footer .row a:hover {
  color: #fff;
  cursor: pointer;
}
.liststyle{
  display: flex;
  flex-direction: row;
  gap: 35px;
  list-style-type: none;
 margin-right: 40px;
}
.privacy-container{
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  margin: 10px 40px;
  
  padding: 30px 40px;
}
.pph{
  margin: 10px 0;
  font-size: 24px;
}
.pp{
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
  margin: 10px 0;
}
.terms-container {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  margin: 30px 40px;
  padding: 30px 40px;
}

.tth {
  font-size: 28px;
  margin: 30px 0 10px 0;
}

.ttp {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.tcl {
  margin-bottom: 20px;
  margin-left: 40px;
}

.tcli {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.tthh1 {
  font-size: 48px;
  margin-bottom: 30px;
  text-align: center;
}
.bb::before{
  content: '\2022'; /* Bullet point character */
  margin-right: 5px; /* Adjust spacing */
}
.SECBUT{
  margin: 10px;
}
.labelstoChange{
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  padding: 4px 0px;
}

.faq-container {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  margin: 40px auto;
  padding: 30px 40px;
  max-width: 850px;
}

.faqh1 {
  font-size: 36px;
  margin: 10px 0;
  text-align: center;
}

.faqh2 {
  font-size: 24px;
  margin: 20px 0 10px;
}

.faqp {
  font-size: 16px;
  line-height: 1.5;
}

.faqp:last-child {
  margin-bottom: 0;
}


@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .HomeMain {
    flex-direction: column;
    padding: 10px;
  }
  .FImage1{
    width: 100%;
    height: 180px;
    margin: 10px;
    margin-top: 20px;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
  }
  .FImage2{
    width: 210%;
    height: 170px;
    margin: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
  }
  .SiteTitle{
    font-size: medium;
  }
  .NavBut3{
    font-size: 13px;
  }

  .Top1,.footer {
    width: 100%;
  }
.boxcont{
  width: 90%;
}
.Top2_text{
  display: flex;
  align-items: center;
  margin: 10px 30px;
  padding: 0px 10px;
  width: 90%;
}
  .Homeboxbut {
    flex-direction: column;
  }

  .CLPic, .CVPic {
    margin: 10px 10px;
  }
}